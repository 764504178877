<template>
  <aside
    class="menu is-unselectable"
    :class="{ 'is-invisible': !sidebar }"
  >
    <template v-for="(menu, index) in myMenus">
      <ul class="menu-list" :key="`options.${index}`">
        <template v-for="(option, optionIndex) in menu.options">
          <li :id="option.to" :key="`item.${index}.${optionIndex}`">
            <template v-if="option.children">
              <a
                @click="toggleActiveMenu(option.label)"
              >
                <div>
                  <ion-icon class="ion_icon" :name="option.icon" />
                  {{ option.label }}
                </div>
                <ion-icon
                  :name="activeMenus.includes(option.label) ? 'caret-up-sharp' : 'caret-down-sharp'"
                />
              </a>
              <ul v-if="activeMenus.includes(option.label)">
                <li v-for="(child, subIndex) in option.children" :key="`options.${index}.${subIndex}`">
                  <router-link :to="child.to">
                    <ion-icon v-if="child.icon" class="ion_icon" :name="child.icon" />
                    <div v-else class="simulate_icon" />
                    {{ child.label }}
                  </router-link>
                </li>
              </ul>
            </template>
            <router-link v-else :to="`/${option.to}`">
              <ion-icon class="ion_icon" :name="option.icon" />
              {{ option.label }}
            </router-link>
          </li>
        </template>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import menus from '../../lib/menu'

export default {
  name: 'TheSidebar',
  data () {
    return {
      myMenus: []
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.ui.sidebar,
      activeMenus: state => state.ui.activeMenus,
      activeoption: state => state.ui.activeoption
    })
  },
  methods: {
    ...mapMutations(['toggleActiveMenu']),
    optionActive () {
      const activeElement = document.getElementsByClassName('activeOption')
      if (activeElement.length > 0) activeElement[0].classList.remove('activeOption')
      let element, name, arr
      if (this.activeoption) {
        element = document.getElementById(this.activeoption)
        if (element) {
          name = 'activeOption'
          arr = element.className.split(' ')
          if (arr.indexOf(name) === -1) {
            element.className += ' ' + name
          }
        }
      }
    }
  },
  beforeMount () {
    this.myMenus = menus
  },
  mounted () {
    this.optionActive()
  },
  watch: {
    '$route.path' (newValue, oldValue) {
      this.optionActive()
    }
  }
}
</script>

<style lang="scss" scoped>
  aside {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fdfdfd;
    height: 100%;
    width: 310px;
    padding: 1rem 0;
  }

  aside.is-invisible {
    display: none;
  }

  .menu-list li ul {
    border-left: none;
    margin: 0;
    width: 100%;
    padding-left: 0;
  }

  .menu-list a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.42px;
    color: var(--secondary-gray);
    margin: 1rem 0.5rem;
  }
  .menu-list a > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .image__item {
    margin: 10px;
    width: 20px;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }

  .router-link-active,
  .menu-list a.is-active,
  .menu-list a:hover,
  .menu-list a.is-active:hover {
    border-radius: 8px;
    background-color: #b751b5;
    color: white !important;

  }

  .menu-list a .ion_icon:first-child {
    padding-right: 1rem;
  }

  .simulate_icon {
    width: 30px !important;
  }

  @media screen and (max-width: 1023px) {
    aside {
      width: 100%;
      padding-bottom: 1em;
    }
  }
</style>
