export default [
  {
    title: '',
    index: 1,
    options: [
      {
        id: 1,
        label: 'Dashboard',
        to: 'dashboard',
        icon: 'grid-sharp'
      },
      {
        id: 2,
        label: 'Usuarios',
        to: 'usuarios',
        icon: 'person'
      },
      {
        id: 3,
        label: 'Gestión de servicios/productos',
        icon: 'clipboard',
        children: [
          {
            id: 1,
            label: 'Productos',
            to: '/productos'
          }
        ]
      },
      {
        id: 4,
        label: 'Transacciones',
        to: 'transacciones',
        icon: 'repeat-sharp'
      },
      {
        id: 5,
        label: 'Administradores',
        to: 'administradores',
        icon: 'extension-puzzle-sharp'
      }
    ]
  }
]
